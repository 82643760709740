import React, { Fragment, useRef } from "react";
import styled from "styled-components";
import { css } from "@emotion/core";
import {
  convertFromRaw,
  Editor,
  EditorState,
  CompositeDecorator,
} from "draft-js";
import { useInView } from "react-intersection-observer";
import MayaHomeCosts from "../components/MayaHomeCosts";

import { mediaBlockRenderer } from "../editor/MediaBlockRenderer";
import { Link, linkStrategy } from "../editor/LinkPlugin";
import {
  Link as GatsbyLink,
  FormattedMessage,
  useIntl,
} from "gatsby-plugin-intl";
import Layout from "../layout";
import ExperienceCard from "../components/ExperienceCard";
import ReactPlayer from "react-player/lazy";
import { ButtonConfirm } from "./DonationPage";

const decorator = new CompositeDecorator([
  {
    strategy: linkStrategy,
    component: Link,
  },
]);

const convertToEditorState = (editorContent) => {
  const content = convertFromRaw(JSON.parse(editorContent));
  const editorState = EditorState.createWithContent(content, decorator);
  return editorState;
};

const settings = {
  dots: true,
  autoplay: true,
  autoplaySpeed: 6000,
  infinite: true,
  speed: 500,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1080,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        infinite: true,
        dots: true,
      },
    },
  ],
};

const scrollToRef = (ref) => window.scrollTo(0, ref.current.node.offsetTop);

const MayaHome = (props) => {
  const { content } = props.pageContext;
  const intl = useIntl();
  const [ref, inView, entry] = useInView({
    triggerOnce: true,
    threshold: 0,
  });
  const directorsRef = useRef(null);
  const experienceRef = useRef(null);
  const executeScroll = (ref) => {
    scrollToRef(ref);
  };
  return (
    <Layout>
      <Section backgroundColor={"#fef4ea"} style={{ padding: 0 }}>
        <FrontHeader image={content.headerPhoto}>
          <p>{content.title}</p>
          <h2>
            Providing inclusive education and care for over eighty children
          </h2>
        </FrontHeader>
        <Section backgroundColor={"#fef4ea"} ref={directorsRef}>
          <InnerRow>
            {/* <BasImage
              src={
                "https://res.cloudinary.com/djhc9jhiq/image/upload/v1598470735/maya/WhatsApp_Image_2020-08-26_at_21.00.23.jpg"
              }
            /> */}
            <Text>
              <Editor
                editorState={convertToEditorState(content.introText)}
                decorators={[
                  {
                    strategy: linkStrategy,
                    component: Link,
                  },
                ]}
                blockRendererFn={mediaBlockRenderer}
                style={{ maxWidth: "100%" }}
                readOnly={true}
              />
            </Text>
          </InnerRow>
          <Title>
            <FormattedMessage id="contribution" />
          </Title>
          <Text>
            <FormattedMessage id="contributionText" />
          </Text>
          <InnerSection to={`/donation/?type=home#step2`}>
            <JoinCta>
            <FormattedMessage
              id="joinCommunity"
              defaultMessage="Join the Maya Home community"
            />
            </JoinCta>
          </InnerSection>
        </Section>
        <Section backgroundColor="white">
          <Title>
            <FormattedMessage
              id="mayaHomeKids"
              defaultMessage="Maya Home Kids"
            />
          </Title>
          <Text>{content.kidsText}</Text>
          <VidWrapper>
            <PlayerWrapper>
              <ReactPlayer
                url="https://vimeo.com/393878235"
                width="100%"
                height="100%"
                className="react-player"
              />
            </PlayerWrapper>
          </VidWrapper>
        </Section>
        <Section></Section>
        <Section ref={experienceRef} backgroundColor={"#fef4ea"}>
          <Title>
            <FormattedMessage
              id="mayaHomeCosts"
              defaultMessage="Maya Home Costs"
            />
          </Title>
          <Text ref={ref}>{content.costsText}</Text>
          <MayaHomeCosts inView={inView} data={content.costs} />
        </Section>
      </Section>
    </Layout>
  );
};

const FrontHeader = styled.div`
  max-width: 100%;
  width: 1100px;
  height: 456px;
  background: url(${(props) => props.image}) no-repeat center center;
  background-size: cover;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: scale(1);
  overflow: hidden;
  p {
    margin-top: 40px;
    font-family: "Nunito Sans";
    font-size: 48px;
    font-weight: 600;
    line-height: 1.07;
    letter-spacing: 0;
    text-align: center;
    color: #ffffff;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  }
  h2 {
    font-family: "Nunito Sans";
    font-size: 24px;
    margin-top: 0px;
    font-weight: 600;
    line-height: 1.07;
    letter-spacing: 0;
    text-align: center;
    color: #ffffff;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    background-size: cover;
    text-align: center;
    position: relative;
    p {
      height: 50px;
      font-family: "Nunito Sans";
      font-size: 30px;
      margin-top: 50px;
      font-weight: 600;
      line-height: 1.04;
      letter-spacing: 0;
    }
    h2 {
      font-family: "Nunito Sans";
      font-size: 20px;
      font-weight: 600;
      line-height: 1.07;
      letter-spacing: 0;
      text-align: center;
      color: #ffffff;
      text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    }
  }
`;

const InnerSection = styled(GatsbyLink)`
  text-decoration: none;
  width: 700px;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 600px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 320px;
  }
`;

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  max-height: 350px;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const VidWrapper = styled.div`
  width: 750px;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 100%;
  }
`;

const Section = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 40px;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InnerRow = styled.div`
  max-width: 1100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    flex-direction: column;
  }
`;

const SliderContainer = styled.div`
  width: 1150px;
  max-height: 400px;
  justify-content: center;
  align-items: center;
  background-color: "#fef4ea";
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 700px;
    margin-top: 180px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 330px;
    margin-top: 40px;
  }
`;

const SliderItem = styled.div`
  max-width: 700px;
  margin-left: 175px;
  padding: 25px;
  border-radius: 3px;
  background-color: transparent;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 700px;
    margin-top: 180px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    max-width: 330px;
    margin-left: 0px;
  }
`;

const Text = styled.div`
  width: 700px;
  display: flex;
  font-family: "Nunito Sans";
  font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0px;
  white-space: pre-line;
  color: #333333;
  margin-top: 16px;
  margin-bottom: 16px;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 600px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 320px;
  }
`;

const BottomImageText = styled.div`
  position: absolute;
  left: 5px;
  font-family: "Nunito Sans";
  bottom: 5px;
  font-size: 12px;
  color: white;
`;

const JoinCta = styled.div`
  opacity: ${(props) => (props.disabled ? "0.7" : "1")};
  width: 100%;
  max-width: 400px;
  height: 60px;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #f58320;
  font-family: "Nunito Sans";
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 36px;
  cursor: pointer;
  color: white;
  letter-spacing: 0.2px;
  text-align: center;
`;

const KidsQuote = styled.div`
  font-family: "Nunito Sans";
  text-align: center;
  width: 1100px;
  font-weight: 900;
  font-size: 18px;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0px;
  white-space: pre-line;
  color: #333333;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 75%;
  }
`;

const ExperienceProducts = styled.div`
  width: 1100px;
  display: flex;
  margin-top: 25px;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    flex-direction: column;
    width: 320px;
  }
`;

const ExperienceLegenda = styled.div`
  width: 190px;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    display: none;
  }
`;

const ExperienceTextItem = styled.div`
  font-family: "Nunito Sans";
  font-size: 18px;
  width: 100%;
  font-weight: Bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #333333;
`;

const Title = styled.div`
  width: 700px;
  height: 44px;
  font-family: "Nunito Sans";
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #333333;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 600px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 320px;
  }
`;

const Quote = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: italic;
  font-style: oblique;
  letter-spacing: 0;
  color: #333333;
`;

const Name = styled.div`
  display: flex;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 900;
  font-weight: Bold;
  letter-spacing: 0;
  color: #333333;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: space-between;
`;

const IconSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icon = styled.img`
  width: 100px;
  height: 100px;
  display: flex;
  margin: auto;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 50px;
    height: 50px;
  }
`;

const CtaContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1100px;
       @media only screen and (min-width: 275px) and (max-width: 480px) {
         width: 100%
         flex-direction: column;
         justify-content: left-align
         left: 0
     }
`;

const Cta = styled.div`
  width: 275px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.8;
  background-color: ${(props) => props.backgroundColor};
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 100%;
  }
`;

const CtaLine = styled.div`
  display: flex;
  font-size: 16px;
  width: 90%;
  font-weight: 900;
  letter-spacing: 0;
  color: #333333;
  margin-bottom: -10px;
  flex-direction: row;
`;

const CtaText = styled.p`
  margin-left: 15px;
  line-height: 40px;
`;

const IconText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  color: white;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    font-size: 12px;
  }
`;

const IconTextBig = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-family: "Nunito Sans";
  font-size: 40px;
  margin-left: 40px;
  margin-right: 40px;
  font-weight: 300;
  letter-spacing: 0;

  @media only screen and (min-width: 275px) and (max-width: 480px) {
    font-size: 30px;
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const backgroundColor = "#e6f2fa";

const BasImage = styled.img`
  display: block;
  width: 45%;
  min-height: 100%;
  height: 560px;
  margin-right: 20px;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 90%;
    height: 50%;
    display: none;
    margin-right: 0px;
  }
`;

export const JoinHomeNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  font-family: "Nunito Sans";
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  color: #333333;
  z-index: 15;
`;

export default MayaHome;
