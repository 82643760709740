import React, { useState } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";
import styled from "styled-components";
// import { FadeTransition } from "react-transition-components";

const getYDirection = (midAngle) => {
  if (midAngle >= 0 && midAngle < 90) {
    const percentage = (midAngle - 0) / (90 - 0);
    const rangeNumber = 20 * percentage;
    return rangeNumber;
  }
  if (midAngle >= 90 && midAngle < 180) {
    const percentage = (midAngle - 180) / (90 - 180);
    const rangeNumber = 20 * percentage;
    return rangeNumber;
  }
  if (midAngle >= 180 && midAngle < 270) {
    const percentage = (midAngle - 180) / (180 - 270);
    const rangeNumber = 20 * percentage;
    return rangeNumber;
  }
  if (midAngle >= 270 && midAngle <= 360) {
    const percentage = (midAngle - 360) / (360 - 270);
    const rangeNumber = 20 * percentage;
    return rangeNumber;
  }
};

const getXDirection = (midAngle) => {
  if (midAngle >= 0 && midAngle < 90) {
    const percentage = (midAngle - 90) / (0 - 90);
    const rangeNumber = -20 * percentage;
    return rangeNumber;
  }
  if (midAngle >= 90 && midAngle < 180) {
    const percentage = (midAngle - 90) / (180 - 90);
    const rangeNumber = -20 * percentage;
    return rangeNumber;
  }
  if (midAngle >= 180 && midAngle < 270) {
    const percentage = (midAngle - 270) / (270 - 180);
    const rangeNumber = 20 * percentage;
    return rangeNumber;
  }
  if (midAngle >= 270 && midAngle <= 360) {
    const percentage = (midAngle - 270) / (270 - 360);
    const rangeNumber = -20 * percentage;
    return rangeNumber;
  }
};

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const yDirection = getYDirection(midAngle);
  const xDirection = getXDirection(midAngle);
  return (
    <Sector
      cx={cx + xDirection}
      cy={cy - yDirection}
      isAnimationActive={true}
      innerRadius={innerRadius}
      outerRadius={outerRadius + 20}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
  );
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="#333333"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const MayaHomeCosts = (props) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onPieEnter = (data, index) => {
    setActiveIndex(index);
  };
  return (
    <Container>
      {props.inView && (
        <StyledPieChart width={400} height={400}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={props.data}
            cx={200}
            cy={200}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={130}
            fill="#333333"
            dataKey="percentage"
            onMouseEnter={onPieEnter}
          >
            {props.data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
                stroke={"#333333"}
              />
            ))}
          </Pie>
        </StyledPieChart>
      )}
      <Legenda>
        {props.data.map((entry, index) => (
          <LegendaItem>
            <LegendaColor backgroundColor={entry.color} />
            <LegendaText>{entry.name}</LegendaText>
          </LegendaItem>
        ))}
      </Legenda>
      <TextContainer>
        <Title>
          {activeIndex > 0 ? props.data[activeIndex].name : props.data[0].name}
        </Title>
        <Text>
          {activeIndex > 0 ? props.data[activeIndex].info : props.data[0].info}
        </Text>
      </TextContainer>
    </Container>
  );
};

const StyledPieChart = styled(PieChart)`
  width: 400px;
  height: 400px;
  left: -50px;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 300px;
    height: 300px;
    left: -50px;
    top: -20px;
  }
`;

const Container = styled.div`
  width: 1100px;
  display: flex;
  flex-direction: row;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    flex-direction: column;
    width: 100%;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    flex-direction: column;
    width: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-top: 80px;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    margin-left: -0px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    margin-top: 20px;
  }
`;

const Legenda = styled.div`
  width: 150px;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: -40px;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    margin-left: -0px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    margin-left: 50px;
    margin-top: -20px;
  }
`;

const LegendaItem = styled.div`
  width: 150px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const LegendaColor = styled.div`
  display: flex;
  width: 35px;
  height: 20px;
  margin-right: 5px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: ${(props) => props.backgroundColor};
  padding: 0;
`;

const LegendaText = styled.div`
  font-family: "Nunito Sans";
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0px;
  white-space: pre-line;
  color: #333333;
`;

const Text = styled.div`
  display: flex;
  width: 500px;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0px;
  white-space: pre-line;
  color: #333333;
  margin-top: 16px;
  margin-bottom: 16px;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 600px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 320px;
  }
`;

const Title = styled.div`
  height: 44px;
  font-family: "Nunito Sans";
  width: 500px;
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #333333;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 600px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 320px;
  }
`;

export default MayaHomeCosts;
