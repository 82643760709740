import styled from "styled-components";
import React from "react";
import { Link, FormattedMessage } from "gatsby-plugin-intl";
import { ButtonConfirm } from "../templates/DonationPage";

const ExperienceCard = (props) => {
  return (
    <ItemCard
      to={`/donation/?amount=${props.data.price}&recurring=1&type=home#step2`}
    >
      <PriceContainer>
        €{props.data.price} / <FormattedMessage id="month" />
      </PriceContainer>
      <ButtonConfirm>
        <FormattedMessage id="join" />
      </ButtonConfirm>
    </ItemCard>
  );
};

const ItemCard = styled(Link)`
  width: 200px;
  height: 250x;
  display: flex;
  padding: 10px;
  flex-direction: column;
  border-radius: 8px;
  background-color: #ffffff;
  align-items: center;
  text-decoration: none;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  :hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  @media only screen and (min-width: 275px) and (max-width: 430px) {
    margin-right: 0px;
    width: 320px;
  }
`;

const PriceContainer = styled.div`
  height: 50px;
  font-family: "Nunito Sans";
  font-size: 18px;
  width: 100%;
  font-weight: Bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #333333;
`;

const TitleContainer = styled.div`
  font-family: "Nunito Sans";
  font-size: 18px;
  width: 100%;
  height: 130px;
  font-weight: Bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #333333;
`;

const Title = styled.p`
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: Bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-bottom: 10px;
  color: #333333;
`;

const SubTitle = styled.p`
  font-family: "Nunito Sans";
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-bottom: 10px;
  color: #333333;
`;

const BulletPoints = styled.ul`
  font-family: "Nunito Sans";
  font-size: 14px;
  width: 100%;
  list-style-type: circle;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  flex-direction: column;
  letter-spacing: 0.2px;
  display: flex;
  padding: 0;
  justify-content: flex-start;
  color: #333333;
`;

export default ExperienceCard;
