import React from "react";
import styled from "styled-components";
import {Img} from "react-image-loading";

export const mediaBlockRenderer = block => {
    if (block.getType() === "atomic") {
        return {
            component: Media,
            editable: false
        };
    }

    return null;
};

const Image = props => {
    if (!!props.src) {
        return <ImagePreview src={props.src} />;
    }
    return null;
};

const Media = props => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const {src} = entity.getData();
    const type = entity.getType();

    let media;

    if (type === "image") {
        media = <Image src={src}/>;
    }

    return media;
}

const ImagePreview = styled(Img)`
    max-width: 1100px;
    max-height: 500px;
    margin: auto;
`;